import React, {useEffect, useState, useMemo} from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import parse from 'autosuggest-highlight/parse';
import { debounce } from '@mui/material/utils';

import { useMap, useMapsLibrary } from '@vis.gl/react-google-maps';

import TUXTextField from './TUXTextField';

const autocompleteService = { current: null };

export default function AutoCompleteTextField(attrs) {
  const [value, setValue] = useState(attrs?.value || null);

  //const [inputValue, setInputValue] = React.useState('');
  const inputValue = attrs.value;
  const setInputValue = attrs.setValue;
  const [options, setOptions] = useState([]);
  const [region, setRegion] = useState(null);
  const loaded = React.useRef(false);

  const map = useMap();
  const placesLib = useMapsLibrary('places');

  const fetch = useMemo(
    () =>
      debounce((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 400),
    [],
  );

  const getRegion = (region) => {
    if (region === 'sac') {
        return {east: -121, north: 39, west: -122, south: 37};
    } else if (region === 'ja') {
        return {east: -76, north: 18.6, west: -79, south: 17.5};
    } else if (region === 'abq') { // Added Albuquerque region
        return {east: -106.4, north: 35.2, west: -106.7, south: 34.9};
    }
    return undefined;
  };

  useEffect(() => {
    let active = true;

    if (!placesLib) return undefined;

    if (!autocompleteService.current) {
        autocompleteService.current = new placesLib.AutocompleteService();
    }

    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    let request;
    if (attrs.region) {
        request = { input: inputValue, locationRestriction: getRegion(attrs.region) };
    } else {
        request = { input: inputValue };
    }
    fetch( request, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch, placesLib]);

  const textFieldInputProps = {disableUnderline: true};
  return (
    <Autocomplete
      id="google-map-demo"
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.description
      }
      sx={{ minWidth: 250}}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      noOptionsText="No Matching locations"
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        if (attrs.setPlaceId) {
            if (newValue && newValue.place_id != null) {
                attrs.setPlaceId(newValue.place_id);
            } else {
                attrs.setPlaceId('null');
            }
        }
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) =>
        (<TUXTextField {...params} label={attrs.label} fullWidth />)
      }
      renderOption={(props, option) => {
        const matches =
          option.structured_formatting.main_text_matched_substrings || [];

        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length]),
        );

        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item sx={{ display: 'flex', width: 44 }}>
                <LocationOnIcon sx={{ color: 'text.secondary' }} />
              </Grid>
              <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                {parts.map((part, index) => (
                  <Box
                    key={index}
                    component="span"
                    sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                  >
                    {part.text}
                  </Box>
                ))}
                <Typography variant="body2" color="text.secondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}
