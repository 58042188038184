import './TUXAppBarLink.css';
import React from 'react';
import { Link } from 'react-router-dom';
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';

function TUXAppBarLink(props) {
    const colorLink = props?.color || 'white';
    let linkClassName = colorLink;

    const linkMap = new Map();
    linkMap.set('white', {link: 'LinkWhite', inner: 'InnerCirleWhite'});
    linkMap.set('clear', {link: 'LinkClear', inner: 'InnerCirleClear'});
    linkMap.set('orange', {link: 'LinkOrange', inner: 'InnerCirleOrange'});

    if (props?.bordered) {
        linkClassName = `${linkClassName} bordered`;
    }
    return (
        <div className={linkClassName}>
            <Link className={linkMap.get(colorLink).link} to={props.to} reloadDocument={props?.reload || false}>
                <div className="TextContainer">
                    <div className={props?.centered ? "InnerText Centered": "InnerText"}>{props.text}</div>
                </div>
            </Link>
        </div>);
}

export default TUXAppBarLink;