import './ResetPassword.css';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';

import TUXTextField from '../components/TUXTextField';
import TUXActionButton from '../components/TUXActionButton';

import { getAuth, sendPasswordResetEmail } from "firebase/auth";

import { FirebaseContext } from '../providers/FirebaseContext.js';

function ResetPassword() {
    const [email, setEmail] = useState(null);
    const [error, setError] = useState(null);
    const nav = useNavigate();

    const {firebaseApp} = useContext(FirebaseContext);

    const onEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const sendResetLink = () => {
        const auth = getAuth();
        sendPasswordResetEmail(auth, email)
          .then(() => {
            nav('/signin');
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            setError(errorMessage);
          });
    };

    return (
        <div className="ResetPassword">
            <div className="ResetPasswordContainer">
                <Stack spacing={2} direction="column">
                    {error && (<div className="error">{error}</div>)}
                    <TUXTextField label="Email" onChange={onEmailChange} type="email"/>
                    <TUXActionButton flex={true} onClick={sendResetLink}>Send Reset Link</TUXActionButton>
                </Stack>
            </div>
        </div>
    );
}

export default ResetPassword;