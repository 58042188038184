import './RideLeg.css';
import blackCar from './images/black-white-car.png';
import blackSuv from './images/black-white-suv.png';
import blackVan from './images/black-white-van.png';
import blackBus from './images/black-white-bus.png'; // './images/black-white-bus-shuttle.png'; // 
import React, { useState, useEffect } from 'react';

import Checkbox from '@mui/material/Checkbox';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import { Unstable_Popup as BasePopup } from '@mui/base/Unstable_Popup';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import {Map, Marker, useMap, useMapsLibrary} from '@vis.gl/react-google-maps';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import AutoCompleteTextField from './AutoCompleteTextField';
import { VIEWPORT_LIMIT, MAX_PASSENGERS, MAX_BAGS, MAX_NON_STANDARD_BAGS, 
        ROUND_TRIP, ONE_WAY, HOURLY,
        MIN_FARE_DRIVER_SAFE } from '../libs/constants.js';
import { getVehicleType, getInitalDate, getVehicleCountBySelection, getVehicleCount, getVehicleRateByType, getVehicleDollarsBySelection } from '../libs/utils.js';

import TUXTextField from '../components/TUXTextField';

const routeService = { current: null };

function RideLeg(props) {
    const disabledFeature = true;
    dayjs.extend(utc);
    dayjs.extend(timezone);
    const [rideLegInfo, setRideLegInfo] = useState({});
    const timeZone = props.region === 'ja'? 'Jamaica': 'America/Los_Angeles';
    const initialDay = getInitalDate(props.region).add(2, 'hour').tz(timeZone);
    const [viewPortWidth, setViewPortWidth] = useState(window.innerWidth);
    const [pickUp, setPickUp] = useState(props?.pickUp || null);
    const [dropOff, setDropOff] = useState(props?.dropOff || null);
    const [pickUpPlaceId, setPickUpPlaceId] = useState(props?.pickUpPlaceId || null);
    const [dropOffPlaceId, setDropOffPlaceId] = useState(props?.dropOffPlaceId || null);
    const [date, setDate] = useState(initialDay);
    const [time, setTime] = useState(initialDay);
    // Round trip
    const [returnPickUp, setReturnPickUp] = useState(props?.pickUp || null);
    const [returnDropOff, setReturnDropOff] = useState(props?.dropOff || null);
    const [returnPickUpPlaceId, setReturnPickUpPlaceId] = useState(props?.pickUpPlaceId || null);
    const [returnDropOffPlaceId, setReturnDropOffPlaceId] = useState(props?.dropOffPlaceId || null);
    const [returnDate, setReturnDate] = useState(initialDay);
    const [returnTime, setReturnTime] = useState(initialDay);

    const [bags, setBags] = useState(1);
    const [overSizedBags, setOverSizedBags] = useState(0);
    const [irregularBags, setIrregularBags] = useState(0);
    const [allDay, setAllDay] = useState(false);
    const [passengersAnchor, setPassengersAnchor] = useState(null);
    const [requestedVehicleType, setRequestedVehicleType] = useState('car');
    const [calcVehicleType, setCalcVehicleType] = useState('car');
    const [calcVehicleCount, setCalcVehicleCount] = useState(1);
    const [currentVehicleIndex, setCurrentVehicleIndex] = useState(0);
    const [passengers, setPassengers] = useState(1);
    const [vehicleType, setVehicleType] = useState('car');
    const [vehicleRate, setVehiclerate] = useState(getVehicleRateByType(vehicleType, false)); // todo:eahj, fix, compud issue should be about $55.. not $110 for 15 mins,...useState(Math.max(getVehicleRateByType(vehicleType, false), MIN_FARE_DRIVER_SAFE));

    const tripType = props?.tripType || null;
    
    const handlePassengersClick = (event) => {
        setPassengersAnchor(passengersAnchor ? null : event.currentTarget);
    };

    const passengersOpen = Boolean(passengersAnchor);
    const passengersId = passengersOpen ? 'passengers-popper' : undefined;

    const [bagsAnchor, setBagsAnchor] = useState(null);
    const [vehicleAnchor, setVehicleAnchor] = useState(null);

    const [estimatedDuration, setEstimatedDuration] = useState(null);
    const [returnEstimatedDuration, setReturnEstimatedDuration] = useState(null);
    const map = useMap();
    const routeLib = useMapsLibrary('routes');

    const handleBagsClick = (event) => {
        setBagsAnchor(bagsAnchor ? null : event.currentTarget);
    };

    const handleVehicleClick = (event) => {
        setVehicleAnchor(vehicleAnchor ? null : event.currentTarget);
    };

    const updateEstimatedDuration = () => {
        if (!routeLib) {
            return;
        }

        if (!routeService.current) {
            routeService.current = new routeLib.DistanceMatrixService();
        }

        if (!routeService.current) {
            return;
        }

        if (dropOffPlaceId === null || pickUpPlaceId === null ||
           dropOffPlaceId === 'null' || pickUpPlaceId === 'null' ||
           dropOffPlaceId === undefined || pickUpPlaceId === undefined) {
            setEstimatedDuration({});
            onChange({updatedEstimatedDuration: {}});
            return;
        }
        const departureDateTime = dayjs.tz(`${date.year()}/${date.month() + 1}/${date.date()} ${time.hour()}:${time.minute()}`, props?.region === 'ja'? 'Jamaica': 'America/Los_Angeles').format();
        const departureTime = new Date(departureDateTime);
        routeService.current.getDistanceMatrix({
            destinations: [{placeId: dropOffPlaceId}],
            origins: [{placeId: pickUpPlaceId}],
            transitOptions: {departureTime: departureTime},
            travelMode: routeLib.TravelMode.DRIVING}, (response, status) => {
                const duration = response.rows[0].elements[0].duration;
                setEstimatedDuration(duration);
                onChange({updatedEstimatedDuration: duration});
            });

    };

    const updateReturnEstimatedDuration = () => {
            if (!routeLib) {
                return;
            }

            if (!routeService.current) {
                routeService.current = new routeLib.DistanceMatrixService();
            }

            if (!routeService.current) {
                return;
            }

            if (returnDropOffPlaceId === null || returnPickUpPlaceId === null ||
               returnDropOffPlaceId === 'null' || returnPickUpPlaceId === 'null' ||
               returnDropOffPlaceId === undefined || returnPickUpPlaceId === undefined) {
                setReturnEstimatedDuration({});
                onChange({updatedReturnEstimatedDuration: {}});
                return;
            }
            const departureDateTime = dayjs.tz(`${returnDate.year()}/${returnDate.month() + 1}/${returnDate.date()} ${returnTime.hour()}:${returnTime.minute()}`, props?.region === 'ja'? 'Jamaica': 'America/Los_Angeles').format();
            const departureTime = new Date(departureDateTime);
            routeService.current.getDistanceMatrix({
                destinations: [{placeId: returnDropOffPlaceId}],
                origins: [{placeId: returnPickUpPlaceId}],
                transitOptions: {departureTime: departureTime},
                travelMode: routeLib.TravelMode.DRIVING}, (response, status) => {
                    const duration = response.rows[0].elements[0].duration;
                    setReturnEstimatedDuration(duration);
                    onChange({updatedReturnEstimatedDuration: duration});
                });

        };

    const bagsOpen = Boolean(bagsAnchor);
    const bagsId = bagsOpen ? 'bags-popper' : undefined;

    const vehicleOpen = Boolean(vehicleAnchor);
    const vehicleId = vehicleOpen ? 'vehicle-popper' : undefined;

    const getRideLegInfo = (updated) => {
        return {
            // One-way or initial trip
            from: updated?.updatedFrom || pickUp,
            fromPlaceId: updated?.updatedFromPlaceId || pickUpPlaceId,
            to: updated?.updatedTo || dropOff,
            toPlaceId: updated?.updatedToPlaceId || dropOffPlaceId,
            date: updated?.updatedDate || date,
            time: updated?.updatedTime || time,
            // Round-trip
            returnFrom: updated?.updatedReturnFrom || returnPickUp,
            returnFromPlaceId: updated?.updatedReturnFromPlaceId || returnPickUpPlaceId,
            returnTo: updated?.updatedReturnTo || returnDropOff,
            returnToPlaceId: updated?.updatedReturnToPlaceId || returnDropOffPlaceId,
            returnDate: updated?.updatedReturnDate || returnDate,
            returnTime: updated?.updatedReturnTime || returnTime,

            passengers: updated?.updatedPassengers || passengers,
            vehicleRate: updated?.updatedVehicleRate || vehicleRate,
             vehicleCount: updated?.updatedVehicleCount || getVehicleCountBySelection(requestedVehicleType, passengers),
             vehicleType: updated?.updatedVehicleType || requestedVehicleType,
            bags: {
                luggage: updated?.updatedBags || bags,
                overSizedBags: updated?.updatedOverSizedBags || overSizedBags,
                irregularBags: updated?.updatedIrregularBags || irregularBags
                },
            allDay: ('updatedAllDay' in updated) ? updated.updatedAllDay : allDay,
            estimatedDuration: updated?.updatedEstimatedDuration ||estimatedDuration,
            returnEstimatedDuration: updated?.updatedReturnEstimatedDuration || returnEstimatedDuration
            };
    };

    const onChange = (updated) => {
        if (props.onChange) {
            const newRideLegInfo = getRideLegInfo(updated);
            setRideLegInfo(newRideLegInfo);
            props.onChange(newRideLegInfo);

        }
    };

    const onChangeTime = (value) => {
        if (value) {
          const newTime = value.tz(timeZone);
          setTime(newTime);
          onChange({updatedTime: newTime});
        }
    };

    const onChangeDate = (value) => {
        if (value) {
          const newDate = value.tz(timeZone);
          setDate(newDate);
          onChange({updatedDate: newDate});
        }
    };

    const onChangeReturnTime = (value) => {
        if (value) {
          const newTime = value.tz(timeZone);
          setReturnTime(newTime);
          onChange({updatedReturnTime: newTime});
        }
    };

    const onChangeReturnDate = (value) => {
        if (value) {
          const newDate = value.tz(timeZone);
          setReturnDate(newDate);
          onChange({updatedReturnDate: newDate});
        }
    };

    const incPassengers = () => {
        if (passengers < MAX_PASSENGERS) {
            const newPassengerCount = passengers + 1;
            setPassengers(newPassengerCount);
            onChange({updatedPassengers: newPassengerCount, updatedVehicleCount:  getVehicleCount(vehicleType, newPassengerCount)  });
        }
    };

    const decPassengers = () => {
        if (passengers > 1) {
            const newPassengerCount = passengers - 1;
            setPassengers(newPassengerCount);
            onChange({updatedPassengers: newPassengerCount,  updatedVehicleCount:  getVehicleCount(vehicleType, newPassengerCount) });
        }
    };

    const incBags = () => {
        if (bags < MAX_BAGS) {
            setBags(bags + 1);
            onChange({updatedBags: bags + 1});
        }
    };

    const decBags = () => {
        if (bags > 0) {
            setBags(bags - 1);
            onChange({updatedBags: bags - 1});
        }
    };

    const incOverSizeBags = () => {
        if (overSizedBags < MAX_NON_STANDARD_BAGS) {
            setOverSizedBags(overSizedBags + 1);
            onChange({updatedOverSizedBags: overSizedBags + 1});
        }
    };

    const decOverSizeBags = () => {
        if (overSizedBags > 0) {
            setOverSizedBags(overSizedBags - 1);
            onChange({updatedOverSizedBags: overSizedBags - 1});
        }
    };

    const incIrregularBags = () => {
        if (irregularBags < MAX_NON_STANDARD_BAGS) {
            setIrregularBags(irregularBags + 1);
            onChange({updatedIrregularBags: irregularBags + 1});
        }
    };

    const decIrregularBags = () => {
        if (irregularBags > 0) {
            setIrregularBags(irregularBags - 1);
            onChange({updatedIrregularBags: irregularBags - 1});
        }
    };

    const setFrom = (from) => {
        setPickUp(from);
        if (tripType === ROUND_TRIP) {
            setReturnDropOff(from);
            onChange({updatedFrom: from, updatedReturnTo: from});
        } else {
            onChange({updatedFrom: from});
        }
    };

    const setTo = (to) => {
        setDropOff(to);
        if (tripType === ROUND_TRIP) {
            setReturnPickUp(to);
            onChange({updatedTo: to, updatedReturnFrom: to});
        } else {
            onChange({updatedTo: to});
        }
    };

    const setFromPlaceId = (placeId) => {
        setPickUpPlaceId(placeId);
        if (tripType === ROUND_TRIP) {
            setReturnDropOffPlaceId(placeId);
            onChange({updatedFromPlaceId: placeId, updatedReturnToPlaceId: placeId});
        } else {
            onChange({updatedFromPlaceId: placeId});
        }
    };

    const setToPlaceId = (placeId) => {
        setDropOffPlaceId(placeId);
        if (tripType === ROUND_TRIP) {
            setReturnPickUpPlaceId(placeId);
            onChange({updatedToPlaceId: placeId, updatedReturnFromPlaceId: placeId});
        } else {
            onChange({updatedToPlaceId: placeId});
        }
    };

    const setReturnFromPlaceId = (placeId) => {
        setReturnPickUpPlaceId(placeId);
        onChange({updatedReturnFromPlaceId: placeId});
    };

    const setReturnToPlaceId = (placeId) => {
        setReturnDropOffPlaceId(placeId);
        onChange({updatedReturnToPlaceId: placeId});
    };

    const getVehicleImage = () => {
        return vehicleTypes[currentVehicleIndex].image;
    };

    const getVehicleTypeImage = (vehicleType) => {
        if (vehicleType === 'car') {
            return blackCar;
        } else if (vehicleType === 'suv') {
            return blackSuv;
        } else if (vehicleType === 'van') {
            return blackVan;
        } else if (vehicleType === 'bus') {
            return blackBus;
        }
    };

    const getCurrentVehicleTypeImage = () => {
        return vehicleTypes[currentVehicleIndex].image;
    };
    
    const vehicleTypes = [
        { type: 'car', image: blackCar },
        { type: 'suv', image: blackSuv },
        { type: 'van', image: blackVan },
        { type: 'bus', image: blackBus }
    ];

    const handleVehicleTypeClick = () => {
        const nextIndex = (currentVehicleIndex + 1) % vehicleTypes.length;
        setCurrentVehicleIndex(nextIndex);
        const updatedVehicleType = vehicleTypes[nextIndex].type;
        const updatedVehicleCount = getVehicleCountBySelection(updatedVehicleType, passengers);
        const updatedVehicleRate = getVehicleRateByType(updatedVehicleType, false);

        setRequestedVehicleType(updatedVehicleType);
            //getVehicleCount(vehicleType, newPassengerCount)
        setCalcVehicleCount(updatedVehicleCount);
        // eahj, consolidate
        setVehicleType(updatedVehicleType);
        setVehiclerate(updatedVehicleRate);

        onChange({ updatedVehicleCount:  updatedVehicleCount, updatedVehicleType: updatedVehicleType, updatedVehicleRate: updatedVehicleRate });
    };

    const handleVehicleTypeChange = (newVehicleType) => {
        const updatedVehicleCount = getVehicleCountBySelection(newVehicleType, passengers);
        const updatedVehicleRate = getVehicleRateByType(newVehicleType, false);

        setRequestedVehicleType(newVehicleType);
            //getVehicleCount(vehicleType, newPassengerCount)
        setCalcVehicleCount(updatedVehicleCount);
        // eahj, consolidate
        setVehicleType(newVehicleType);
        setVehiclerate(updatedVehicleRate);

        onChange({ updatedVehicleCount:  updatedVehicleCount, updatedVehicleType: newVehicleType, updatedVehicleRate: updatedVehicleRate });
    };

    useEffect( () => {
        updateEstimatedDuration();
    }, [pickUpPlaceId, dropOffPlaceId, routeLib]);

    useEffect( () => {
        updateReturnEstimatedDuration();
    }, [returnPickUpPlaceId, returnDropOffPlaceId, routeLib]);

    useEffect( () => {
        if (tripType === ROUND_TRIP) {
            setReturnDropOff(pickUp);
            setReturnPickUp(dropOff);
            setReturnDropOffPlaceId(pickUpPlaceId);
            setReturnPickUpPlaceId(dropOffPlaceId);
            onChange({updatedReturnToPlaceId: pickUpPlaceId,
                      updatedReturnFromPlaceId: dropOffPlaceId,
                      updatedReturnTo: pickUp,
                      updatedReturnFrom: dropOff,});
        } else {
            setReturnDropOff(null);
            setReturnPickUp(null);
            setReturnDropOffPlaceId(null);
            setReturnPickUpPlaceId(null);
            onChange({updatedReturnToPlaceId: null,
                      updatedReturnFromPlaceId: null,
                      updatedReturnTo: null,
                      updatedReturnFrom: null,});
            setReturnEstimatedDuration({});
        }

        }, [tripType]
    );

    useEffect(() => {
        function handleResize() {
            setViewPortWidth(window.innerWidth);
        }
        window.addEventListener('resize', handleResize)
    });

    const { allDay: isAllDay, vehicleType: type, vehicleRate: rate, vehicleCount: count, estimatedDuration: duration } = rideLegInfo;
    console.log('Destructured Parameters:', { isAllDay, type,  rate, count, duration });
    console.log('Full Props Object:', props);
   
    return (
    <div className="RideLeg">
        <Stack spacing={2} direction="column">
            <Stack spacing={4} direction={viewPortWidth > VIEWPORT_LIMIT ? "row": "column"}>
                <AutoCompleteTextField key="ride-leg-pickup" label="Pickup Location" value={pickUp} setValue={setFrom} setPlaceId={setFromPlaceId} region={props.region}/>
                <AutoCompleteTextField key="ride-leg-dropoff" label="Drop-off Location" value={dropOff} setValue={setTo} setPlaceId={setToPlaceId} region={props.region !== 'sac' && props.region !== 'abq' ? props.region : undefined }/>
                <DatePicker label="Pickup Date" variant="filled" onChange={onChangeDate} value={date} minDate={initialDay}/>
                <TimePicker label="Pickup Time" variant="filled" onChange={onChangeTime} value={time} />
            </Stack>
           { props?.tripType === ROUND_TRIP && (<Stack spacing={4} direction={viewPortWidth > VIEWPORT_LIMIT ? "row": "column"}>
                <TUXTextField label="Reurn Pickup Location" variant="outlined" value={returnPickUp} inputProps={{ readOnly: true }} InputLabelProps={{ shrink: (returnDropOff !== '')}}  sx={{minWidth: 250, maxWidth: 300}}/>
                <TUXTextField label="Return Drop-off Location" variant="outlined" value={returnDropOff}  inputProps={{ readOnly: true }}  InputLabelProps={{ shrink: (returnPickUp !== '')}}  sx={{minWidth: 250, maxWidth: 300}}/>
                <DatePicker label="Return Pickup Date" variant="filled" onChange={onChangeReturnDate} value={date} minDate={initialDay}/>
                <TimePicker label="Return Pickup Time" variant="filled" onChange={onChangeReturnTime} value={time} />
            </Stack>)}
            <Stack spacing={4} direction={viewPortWidth > VIEWPORT_LIMIT ? "row": "column"}>
                <div className="PassengerOuter">
                    <div className="PassengerLabel">Passengers</div>
                    <div className="Passenger">
                        <Button onClick={decPassengers} style={{color: 'black'}}><RemoveIcon/></Button>
                        {passengers}
                        <Button onClick={incPassengers} style={{color: 'black'}}><AddIcon/></Button>
                    </div>
                </div>

                <div className="PopupButton">
                    <div className="VehicleTypeOuter">
                        <div className="VehicleTypeLabel">Vehicles</div>
                        <div className="Vehicle">
                            <span className="VehicleSpan">
                                {getVehicleCountBySelection(requestedVehicleType, passengers)} X
                                <img src={getVehicleTypeImage(vehicleType)} alt="Vehicle Type" style={{ height: 35, width: 70, marginLeft: 32 }} />
                            </span>
                            <Button onClick={handleVehicleClick} style={{color: 'black'}}>
                                { !vehicleOpen && (<KeyboardArrowDownIcon />) }
                                { vehicleOpen && (<KeyboardArrowUpIcon/>)}
                            </Button>
                        </div>
                    </div>

                    <BasePopup id={vehicleId} open={vehicleOpen} anchor={vehicleAnchor}>
                        <ClickAwayListener onClickAway={() => {setVehicleAnchor(null);}}>
                            <div className="Popup">
                                <Stack direction="column">
                                    <Button onClick={() => {handleVehicleTypeChange('car');}} className="VehicleTypeButton">
                                        <div className="VehicleTypeDiv">
                                            <span className="SpanLeft">{getVehicleCountBySelection('car', passengers)} X</span>
                                            <span className="VehicleImage"><img  src={getVehicleTypeImage('car')} alt="Car" style={{ height: 35, width: 70 }} /></span>
                                            <span className="SpanRight">{getVehicleDollarsBySelection('car', passengers)}</span>
                                         </div>
                                    </Button>
                                    <Button onClick={() => {handleVehicleTypeChange('suv');}} className="VehicleTypeButton">
                                        <div className="VehicleTypeDiv">
                                            <span className="SpanLeft">{getVehicleCountBySelection('suv', passengers)} X</span>
                                            <span className="VehicleImage"><img src={getVehicleTypeImage('suv')} alt="SUV" style={{ height: 35, width: 70 }} /></span>
                                            <span className="SpanRight">{getVehicleDollarsBySelection('suv', passengers)}</span>
                                         </div>
                                    </Button>
                                    <Button onClick={() => {handleVehicleTypeChange('van');}} className="VehicleTypeButton">
                                        <div className="VehicleTypeDiv">
                                            <span className="SpanLeft">{getVehicleCountBySelection('van', passengers)} X</span>
                                            <span className="VehicleImage"><img src={getVehicleTypeImage('van')} alt="Van" style={{ height: 35, width: 70}} /></span>
                                            <span className="SpanRight">{getVehicleDollarsBySelection('van', passengers)}</span>
                                        </div>
                                    </Button>
                                    <Button onClick={() => {handleVehicleTypeChange('bus');}} className="VehicleTypeButton">
                                        <div className="VehicleTypeDiv">
                                            <span className="SpanLeft">{getVehicleCountBySelection('bus', passengers)} X</span>
                                            <span className="VehicleImage"><img src={getVehicleTypeImage('bus')} alt="Bus" style={{ height: 35, width: 70}} /></span>
                                            <span className="SpanRight">{getVehicleDollarsBySelection('bus', passengers)}</span>
                                        </div>
                                    </Button>
                                </Stack>
                            </div>
                        </ClickAwayListener>
                    </BasePopup>
                </div>

                <div className="PopupButton">
                    <div className="BagsOuter">
                        <div className="BagsLabel">Bags</div>
                        <div className="Bags">
                            <span>{bags + overSizedBags + irregularBags}</span>
                            <Button onClick={handleBagsClick} style={{color: 'black'}}>
                                { !bagsOpen && (<KeyboardArrowDownIcon />) }
                                { bagsOpen && (<KeyboardArrowUpIcon/>)}
                            </Button>
                        </div>
                    </div>
                    <BasePopup id={bagsId} open={bagsOpen} anchor={bagsAnchor}>
                        <ClickAwayListener onClickAway={() => {setBagsAnchor(null);}}>
                            <div className="Popup">
                                <Stack direction="column">
                                    <div>
                                        <div><span className="InfoTitle">Luggage</span><Button onClick={decBags} style={{color: 'black'}}><RemoveIcon/></Button>{bags}<Button onClick={incBags} style={{color: 'black'}}><AddIcon/></Button></div>
                                        <div className="InfoText">Suitcases, Backpacks, etc.</div>
                                    </div>
                                    <div>
                                        <div><span className="InfoTitle">Oversized</span><Button onClick={decOverSizeBags} style={{color: 'black'}}><RemoveIcon/></Button>{overSizedBags}<Button onClick={incOverSizeBags} style={{color: 'black'}}><AddIcon/></Button></div>
                                        <div className="InfoText">Video Equipment, Boxes, etc.</div>
                                    </div>
                                    <div>
                                        <div><span className="InfoTitle">Irregular</span><Button onClick={decIrregularBags} style={{color: 'black'}}><RemoveIcon/></Button>{irregularBags}<Button onClick={incIrregularBags} style={{color: 'black'}}><AddIcon/></Button></div>
                                        <div className="InfoText">Golf Clubs, Strollers, etc.</div>
                                    </div>
                                </Stack>
                            </div>
                        </ClickAwayListener>
                    </BasePopup>
                </div>

                { !disabledFeature && (<div className="AllDay">
                    <Checkbox checked={allDay}  onChange={(event) => { setAllDay(event.target.checked); onChange({updatedAllDay: event.target.checked, updatedVehicleRate: getVehicleRateByType(vehicleType, event.target.checked)}); }} /> All Day Excursion
                </div>)}

                {estimatedDuration?.text && !returnEstimatedDuration?.text && (<div className="EstimatedDuration"><div class="Inner">Estimated Duration: {estimatedDuration.text}</div></div>)}
                { estimatedDuration?.text && returnEstimatedDuration?.text && (<Stack>
                    <div className="EstimatedDuration"><div class="Inner">Estimated Duration: {estimatedDuration.text}</div></div>
                    <div className="EstimatedDuration"><div class="Inner">Return Estimated Duration: {returnEstimatedDuration.text}</div></div>
                </Stack>)}
            </Stack>
        </Stack>
    </div>
    );
}

export default RideLeg;
