import './Profile.css';
import React, { useEffect, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import { ThemeProvider } from '@mui/material/styles';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';

import MuiPhoneNumber from 'mui-phone-number';

import { BOOK_RIDES_TABLE, COOKIE_ID, RYDERS_TABLE, VIEWPORT_LIMIT, TEXT_FIELD_THEME } from '../libs/constants.js';

import { doc, setDoc, getFirestore } from 'firebase/firestore';
import { getAuth, signOut } from "firebase/auth";

import { getUserInfo, setUpPhoneNumber } from '../libs/utils.js';

import TUXAppBarButton from '../components/TUXAppBarButton';

import { AuthContext } from '../providers/AuthContext.js';
import { FirebaseContext } from '../providers/FirebaseContext.js';

function Profile () {
    const nav = useNavigate();

    const [viewPortWidth, setViewPortWidth] = useState(window.innerWidth);
    const [cookies, setCookie, removeCookie] = useCookies([COOKIE_ID]);

    const {firebaseApp} = useContext(FirebaseContext);
    const {auth, setAuth} = useContext(AuthContext);
    const [loading, setLoading] = useState(true);
    const [userInfo, setUserInfo] = useState(null);
    const [isEdit, setIsEdit] = useState(false);
    const authFromApp = getAuth(firebaseApp);
    const db = getFirestore(firebaseApp);

    const signOutUser = () => {
        signOut(authFromApp).then(() => {
            setAuth({loggedIn: false, userInfo: null});
            removeCookie(COOKIE_ID);
            nav('/');
        });
    };

    const saveProfile = () => {
        setIsEdit(false);
        setLoading(true);
        const ryderRef = doc(db, RYDERS_TABLE, userInfo.id);
        const newUserInfo =  { firstName: userInfo.firstName, lastName: userInfo.lastName, phoneNumber: userInfo.phoneNumber };
        setDoc(ryderRef, newUserInfo, { merge: true })
        .then(() => {
            getUserInfo(db, auth?.userInfo.userId).then( (result) => {
                setUserInfo(result);
                setLoading(false);
            } );
        });
    };

    const updateUserInfo = (update) => {
        const updatedUserInfo = {...userInfo};
        if (update?.firstName) {
            updatedUserInfo.firstName = update.firstName;
        }
        if (update?.lastName) {
            updatedUserInfo.lastName = update.lastName;
        }
        if (update?.phoneNumber) {
            updatedUserInfo.phoneNumber = update.phoneNumber;
        }
        setUserInfo(updatedUserInfo);
    };


    useEffect(() => {
      function handleResize() {
          setViewPortWidth(window.innerWidth);
      }
      window.addEventListener('resize', handleResize)
    });


    if (auth?.loggedIn && loading) {
        getUserInfo(db, auth?.userInfo.userId).then( (result) => {
            setUserInfo(result);
            setLoading(false);
        } );
        return (<div className="Loading"><CircularProgress/></div>);
    }
    if (userInfo != null) {
        return (
            <ThemeProvider theme={TEXT_FIELD_THEME}>
                <div className="Profile">
                    <div className="ProfileContainer">

                    {!isEdit && (<>

                        <h3>First Name</h3>
                        <div>{userInfo.firstName}</div>

                        <h3>Last Name</h3>
                        <div>{userInfo.lastName}</div>

                        <h3>Email</h3>
                        <div>{userInfo.email}</div>

                        <h3>Phone</h3>
                        <div>{userInfo.phoneNumber}</div>
                        <div className="ProfileButtons">
                            <div className="ProfileButton">
                                <TUXAppBarButton onClick={() => setIsEdit(true)} text={"Edit Profile"} color={"orange"}/>
                            </div>
                            <div className="ProfileButton Spacer">
                                <TUXAppBarButton onClick={signOutUser} text={"Sign Out"} />
                            </div>
                        </div>
                    </>)}
                    {isEdit && (<>

                        <h3>First Name</h3>
                        <div><TextField label="First Name" variant="outlined" value={userInfo.firstName} onChange={(event) => { updateUserInfo({firstName: event.target.value}); } } sx={{minWidth: 300, maxWidth: 500}}/></div>

                        <h3>Last Name</h3>
                        <div><TextField label="Last Name" variant="outlined" value={userInfo.lastName} onChange={(event) => { updateUserInfo({lastName: event.target.value}); } } sx={{minWidth: 300, maxWidth: 500}}/></div>

                        <h3>Email</h3>
                        <div>{userInfo.email}</div>
                        <h3>Phone</h3>
                        <div>
                            <MuiPhoneNumber variant="outlined" label="Phone Number" defaultCountry={'us'} value={userInfo.phoneNumber} onChange={(value) => { let newPhoneNumber = setUpPhoneNumber(userInfo.phoneNumber, value); updateUserInfo({phoneNumber: value}); }} sx={{minWidth: 300, maxWidth: 500}}/>
                        </div>
                        <div className="ProfileButtons">
                            <div className="ProfileButton">
                                <TUXAppBarButton onClick={() => saveProfile()} text={"Save Profile"} color={"orange"}/>
                            </div>
                            <div className="ProfileButton">
                                <TUXAppBarButton onClick={() => setIsEdit(false)} text={"Cancel"}/></div>
                            </div>
                            <div className="ProfileButton Spacer">
                                <TUXAppBarButton onClick={signOutUser} text={"Sign Out"} />
                            </div>
                    </>)}

                </div>
            </div>
         </ThemeProvider>);
    }
}

export default Profile;
