import './TUXButton.css';
import React from 'react';
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';

function TUXButton(props) {
    const buttonClassName = props?.color || 'white';

    const buttonMap = new Map();
    buttonMap.set('white', {button: 'ButtonWhite', inner: 'InnerCirleWhite'});
    buttonMap.set('clear', {button: 'ButtonClear', inner: 'InnerCirleClear'});
    return (
        <div className={buttonClassName}>
            <button className={buttonMap.get(buttonClassName).button}
                onClick={props.onClick}>
                <div className="TextContainer">
                    <div className="InnerText">{props.text}</div>
                    <div className={buttonMap.get(buttonClassName).inner}><NavigateNextOutlinedIcon fontSize={"small"}/></div>
                </div>
                </button>
        </div>);
}

export default TUXButton;