import './Logo.css';
import React from 'react';
import { Link } from 'react-router-dom';

function Logo() {
    return (
        <div className="LogoContainer">
        </div>
    );
}

function LogoTextLink() {
    return (<div className="LogoBox">
            <Logo/> <Link to="/" className="LogoLink">TUXRydes</Link>
         </div>);
}

export {Logo, LogoTextLink} ;