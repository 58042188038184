import './RideDetails.css';
import '../pages/BookRide.css';
import React, { useContext, useState } from 'react';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { getRideStatus, getServiceLevel, printDate } from '../libs/utils.js';
import { BOOKED_RIDES_TABLE, PHONE } from '../libs/constants.js';

import { doc, setDoc, getFirestore } from 'firebase/firestore';

import { FirebaseContext } from '../providers/FirebaseContext.js';

import RideMap from'./RideMap';

function RideDetails(props) {
    const ride = props.ride;
    const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
    const {firebaseApp} = useContext(FirebaseContext);
    const db = getFirestore(firebaseApp);

    const priceFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
    const [status, setStatus] = useState(getRideStatus(ride));

    const onYesCancelClick = () => {
        const rydeRef = doc(db, BOOKED_RIDES_TABLE, ride.id);
        setDoc(rydeRef, { status: 'CANCELED' }, { merge: true }).then(() => {
            setStatus('CANCELED');
            setCancelDialogOpen(false);
        });
    };

    return (
        <div className="RideDetails">
            <div className="DetailsTitle"><Button style={{color: '#F85804'}}  onClick={() => props.onBack() }><ArrowBackIcon /></Button> Ride Details</div>
            <div className="ConfirmationCode">Confirmation: {ride.confirmationCode}</div>
            <div className="Status">Staus: {status} </div>
            <div className="ServiceLevel">Service Level: {getServiceLevel(ride.level)}</div>
            <div className="CostBreakDown">
                <div className="CostRow">
                    <div className="CostCell">TuxRydes Fee</div>
                    <div className="CostCell">{priceFormatter.format(ride.price.fee)}</div>
                </div>
            </div>
            <div className="CostTotal">
                <div className="CostRow">
                    <div className="CostCell">CC (Paid)</div>
                    <div className="CostCell">{priceFormatter.format(ride.price.total)}</div>
                </div>
            </div>
            {
                ride.legs.map( (leg, i) => {
                    return (
                    <>
                        <div>
                            <h4>Ride {i + 1}</h4>
                            <RideMap from={leg.fromPlaceId} to={leg.toPlaceId} duration={leg?.estimatedDuration?.value || 'N/A'}/>
                            <h4>Pickup: {printDate(leg.pickUpDateTime)}</h4>
                            <p>{leg.from}</p>
                            <h4>Drop-off</h4>
                            <p>{leg.to}</p>
                            <h4>Estimated Duration</h4>
                            <p>{leg?.estimatedDuration?.text || 'N/A'}</p>
                        </div>
                        {leg?.returnEstimatedDuration?.value && (
                        <div>
                            <h4>Ride {i + 2}</h4>
                            <RideMap from={leg.returnFromPlaceId} to={leg.returnToPlaceId} duration={leg.returnEstimatedDuration.value}/>
                            <h4>Pick-up: {printDate(leg.returnPickUpDateTime)}</h4>
                            <p>{leg.returnFrom}</p>
                            <h4>Drop-off</h4>
                            <p>{leg.returnTo}</p>
                            <h4>Return Estimated Duration</h4>
                            <p>{leg.returnEstimatedDuration.text}</p>
                        </div>
                            )}
                    </>
                    );
                })
            }
            {status === 'NOT STARTED' && (
                /*<div>
                    <Button onClick={() => setCancelDialogOpen(true)}>Cancel Ryde</Button>
                    <Dialog open={cancelDialogOpen} onClose={()=> setCancelDialogOpen(false)} >
                        <DialogTitle id="alert-dialog-title">{"Are you sure that you want to cancel your Ryde?"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                            Canceling your Ryde will refund your payment in 3-5 business days.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={onYesCancelClick}>Yes</Button>
                          <Button onClick={() => setCancelDialogOpen(false)} autoFocus>
                            No
                          </Button>
                        </DialogActions>
                    </Dialog>
                </div>*/
                <h4>For assistance call: {PHONE}</h4>
                )}
        </div>);
}

export default RideDetails;