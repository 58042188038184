import './Spacer.css';
import React from 'react';

function Spacer() {
    return (<div className="Spacer"></div>);
}

function SpacerSmall() {
    return (<div className="SpacerSmall"></div>);
}

export { Spacer as default, SpacerSmall };