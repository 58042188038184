import './ConfirmPayment.css';
import React from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

import {PHONE} from '../libs/constants.js';

function ConfirmPayment() {
    const params = useParams();
    return (<div className="ConfirmPayment">
        <h3>Payment was unsuccessful please try again.</h3>
        <p>For assistance call: {PHONE}</p>
        <Link className="ConfirmPaymentLink" to="/schedule">Schedule Ride</Link>
    </div>);
}

export default ConfirmPayment;