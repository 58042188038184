import './SignIn.css';
import React, {useContext, useState, useEffect, ChangeEvent} from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import EmailIcon from '@mui/icons-material/Email';
import GoogleIcon from '@mui/icons-material/Google';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material'; 

import { getAuth, signInWithPopup, signInWithEmailAndPassword, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from 'firebase/firestore';

import { AuthContext } from '../providers/AuthContext.js';
import { FirebaseContext } from '../providers/FirebaseContext.js';

import { COOKIE_ID, COOKIE_MAX_AGE, VIEWPORT_LIMIT } from '../libs/constants.js';
import { getUserInfo } from '../libs/utils.js';

import { LogoTextLink } from '../components/Logo';
import TUXActionButton from '../components/TUXActionButton';
import TUXTextField from '../components/TUXTextField';
import { SpacerSmall } from '../components/Spacer';

function SignIn(props) {
  const [viewPortWidth, setViewPortWidth] = useState(window.innerWidth);
  const [error, setError] = useState(false);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [showPassword, SetShowPassword] = useState(false);
  const [cookies, setCookies] = useCookies([COOKIE_ID]);

  const {firebaseApp} = useContext(FirebaseContext);
  const {auth, setAuth} = useContext(AuthContext);

  const nav = useNavigate();
  const authFromApp = getAuth(firebaseApp);
  const provider = new GoogleAuthProvider();
  const db = getFirestore(firebaseApp);

  const toggleShowPassword = () => {
    SetShowPassword(!showPassword);
  }; 

  const handleMouseDown = (e) => {
    e.preventDefault();
  };

  const signInUsingGoogle = () => {
      signInWithPopup(authFromApp, provider)
        .then(async (result) => {
          const user = result.user;
          const userInfo = await getUserInfo(db, user.uid);
          if (userInfo != null) {
              setAuth({loggedIn: true, userInfo: userInfo});
              setCookies(COOKIE_ID, user.uid, {maxAge: COOKIE_MAX_AGE});
              if (props.afterSignIn) {
                  props.afterSignIn(userInfo);
              } else {
                  nav('/');
              }
          } else {
              nav('/signup');
          }
        }).catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message.replace('Firebase:', '');
          setError(errorMessage);
          // The AuthCredential type that was used.
          const credential = GoogleAuthProvider.credentialFromError(error);
        });

  };

  const signInUsingEmailPassword = () => {
    signInWithEmailAndPassword(authFromApp, email, password)
      .then(async (result) => {
        const user = result.user;
        const userInfo = await getUserInfo(db, user.uid);
        if (userInfo != null) {
            setAuth({loggedIn: true, user: user, userInfo: userInfo});
            /*user.getIdToken().then((idToken) => {
                authFromApp.createSessionCookie(idToken,
                    { expiresIn: COOKIE_MAX_AGE }).then((sessionCookie) => {
                    setCookies(COOKIE_ID, sessionCookie, {maxAge: COOKIE_MAX_AGE});
                });
            });*/
            if (props.afterSignIn) {
                props.afterSignIn(userInfo);
            } else {
                nav('/');
            }
        } else {
            setError('Internal Error: Please contact TUXRydes admin.');
        }
      }).catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message.replace('Firebase:', '');
        setError(errorMessage);
      });

  };

  const onEmailChange = (event: ChangeEvent) => {
    setEmail(event.target.value);
  };

  const onPasswordChange = (event: ChangeEvent) => {
    setPassword(event.target.value);
  };

  const emailLoginDisabled = () => {
    return email === null || password === null;
  };

  useEffect(() => {
      function handleResize() {
          setViewPortWidth(window.innerWidth);
      }
      window.addEventListener('resize', handleResize)
    });

  const isSmallViewPort = (viewPortWidth < VIEWPORT_LIMIT);
  let signInClassName = props.afterSignIn ? "SignInDialog" : "SignIn";
  const textFieldInputProps = { sx: { width: !isSmallViewPort ? 375: 300 } };
  return (
    <div className={ signInClassName }>
    {props.closeAction && (<div className="CloseButton" onClick={() => { props.closeAction(); }}><Button style={{color: '#F85804'}}><CloseIcon/></Button></div>) }
        <div className="SignInContainer">
            <div className="SignInLogo">
                <LogoTextLink />
            </div>
            { !props.afterSignIn && (<div className="SignInMessage">
                Welcome to <span className="OrangeText">TUXRydes</span>
            </div>) }
            <div className="SignInControls">
                {error && (<Typography variant="h6" color={'red'} sx={{textAlign: 'center'}}>Login failed! <br/>{error}</Typography>)}

                <div className="SignInButtonContainer">
                    <TUXActionButton flex={true} centered={true} onClick={signInUsingGoogle}><div className="SignInGoogleText"><GoogleIcon/> Continue with Google</div></TUXActionButton>
                </div>

                <div className="SignInOr">
                ---- OR ----
                </div>

                <div className="SignInTextField" >
                    <TUXTextField label="Email" type="email" onChange={onEmailChange}  InputProps={textFieldInputProps}/>
                </div>
                <SpacerSmall />
                <div className="SignInTextField" >
                    <TUXTextField label="Password" type={showPassword? 'text': 'password'} onChange={onPasswordChange} 
                    value={password}
                    InputProps={{
                      ...textFieldInputProps,
                      endAdornment:(
                        <InputAdornment position='end'>
                          <IconButton onClick={toggleShowPassword} onMouseDown={handleMouseDown} edge='end'>
                            {showPassword? <Visibility className='OrangeText'/>: <VisibilityOff/>}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    />
                </div>
                <div className="SignInResetPassword">
                    <Link to="/resetpassword" className="SignInLink">Forgot password?</Link>
                </div>
                <SpacerSmall />
                <div className="SignInButtonContainer">
                    <TUXActionButton flex={true} onClick={signInUsingEmailPassword} disabled={emailLoginDisabled()}>Login with Email</TUXActionButton>
                </div>
                <div className="SignInLinks">{ !props.afterSignIn &&  (<div>Do not have an account? <Link to="/signup" className="SignInLink">Create an account</Link></div>)}</div>
            </div>
         </div>
    </div>
  );
}

export default SignIn;
