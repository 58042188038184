import './ContactUs.css';
import React from 'react';

import { PHONE } from '../libs/constants.js';

function ContactUs() {
    return (<div className="ContactUs"><span>Contact us: {PHONE}</span></div>);
}

function ContactUsDark() {
    return (<div className="ContactUsDark"><span>Contact us: {PHONE}</span></div>);
}

export { ContactUs, ContactUsDark };