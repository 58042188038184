import './TUXAppBarButton.css';
import React from 'react';
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';

function TUXAppBarButton(props) {
    const colorButton = props?.color || 'white';
    let buttonClassName = colorButton;

    const buttonMap = new Map();
    buttonMap.set('white', {button: 'ButtonWhite', inner: 'InnerCirleWhite'});
    buttonMap.set('clear', {button: 'ButtonClear', inner: 'InnerCirleClear'});
    buttonMap.set('orange', {button: 'ButtonOrange', inner: 'InnerCirleOrange'});

    if (props?.bordered) {
        buttonClassName = `${buttonClassName} bordered`;
    }
    return (
        <div className={buttonClassName}>
            { !(props?.disabled || false) && (<button className={buttonMap.get(colorButton).button}
                onClick={props.onClick}>
                <div className="TextContainer">
                    <div className={props?.centered ? "InnerText Centered": "InnerText"}>{props.text}</div>
                </div>
                </button>)}
            { (props?.disabled || false) && ( <button className="ButtonDisabled">
                    <div className="TextContainer">
                        <div className={props?.centered ? "InnerText Centered": "InnerText"}>{props.text}</div>
                    </div>
                </button>
             )}
        </div>);
}

export default TUXAppBarButton;